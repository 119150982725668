//@flow
import * as React from 'react'
import { userInitialState, userActions } from './user'
import { adminTaskInitialState, adminTaskActions } from './task'
import { taskReportsInitialState,taskReportsActions } from './taskReports'
import { projectReportInitialState, projectReportActions } from './projectReport'
import { batchRunInitialState, batchRunActions } from './batchRun'
import { batchResInitialState, batchResActions } from './batchResultset'
import { mgmtCountsInitialState, mgmtCountsActions } from './mgmtCounts'
import { appVerInitialState, appVerActions } from './appVersion'
import { fileDropInitialState, fileDropActions } from './fileDrop'
import { genPageInitialState,genPageActions } from './genericPage'

const initialState= {
    ...userInitialState,
    ...adminTaskInitialState,
    ...taskReportsInitialState,
    ...projectReportInitialState,
    ...batchRunInitialState,
    ...batchResInitialState,
    ...mgmtCountsInitialState,
    ...appVerInitialState,
    ...fileDropInitialState,
    ...genPageInitialState,
};

const StoreContext: any = React.createContext(initialState)

const Actions = {
    ...userActions,
    ...adminTaskActions,
    ...taskReportsActions,
    ...projectReportActions,
    ...batchRunActions,
    ...batchResActions,
    ...mgmtCountsActions,
    ...appVerActions,
    ...fileDropActions,
    ...genPageActions,
};

const reducer = (state, action) => {
    const act = Actions[action.type];
    const update = act(state,action);
    return { ...state, ...update };
};

type Props = {
    children: React.Node,
}

export const StoreProvider = (props: Props) => {
    const [state, dispatch] = React.useReducer(reducer, initialState)
    return (
        <StoreContext.Provider value={{ state, dispatch}}>
            {props.children}
        </StoreContext.Provider>
    );
};

export const useStore = (store:any) => {
    const { state, dispatch } = React.useContext(StoreContext);
    return { state, dispatch }
}