//@flow
const userStateName = 'user'
const localStore = localStorage.getItem(userStateName)

export const userInitialState = localStore?JSON.parse(localStore):{
    user: { 
        loggedIn: false, 
        error: '', 
        jwtToken: '', 
        userInfo: null, 
        googleProfile: null 
    }
}

type State = {
    +user: {
        +loggedIn: boolean,
        +error: string,
        +jwtToken: string,
        +userInfo: any,
        +googleProfile: any
    }
}

type Action = {
    error: string,
    jwtToken: string,
    userInfo: any,
    googleProfile: any
}

export const ActionLogin = 'login'
export const ActionLogout = 'logout'
export const ActionLoginError = 'loginError'

export const userActions = {
    [ActionLogin]: (state:State, action:Action) => {
        const userState = { user: { 
            loggedIn: true, error: '', 
            jwtToken: action.jwtToken,
            userInfo: action.userInfo,
            googleProfile: action.googleProfile,  
        }};

        localStorage.setItem(userStateName,JSON.stringify(userState))
        return userState
    },
    [ActionLogout]: (state:State) => {
        const userState = { user: { loggedIn: false, error: '' } };

        localStorage.setItem(userStateName,JSON.stringify(userState))
        return userState
    },
    [ActionLoginError]: (state:State,action:Action) => {
        const userState = { user: { loggedIn: false, error: action.error} }

        localStorage.setItem(userStateName,JSON.stringify(userState))
        return userState
    }
};