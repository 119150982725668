//@flow
import * as adminApis from '@lesofi/phoenix-js/lib/phoenixgoapi/admin'
import Package  from '../../package.json'
import { TypeAppVerError,TypeAppVerReceived } from '../store/appVersion'
import compareVersions from 'compare-versions'

const appId = "admin.gigsdata.com"

export const refreshAppUserVersion=()=>{
    adminApis.refreshAppUserVersion(appId,Package.version).then(res => {
        res.json().then(data=>{
            if (data && data['error']){
                console.error(data['error'])
            }
        })
    }).catch(err => {
        console.error(err)
    })
}

export const getAppVersion=(dispatch:any)=>{
    const key = 'app-admin.gigsdata.com-version'
    adminApis.getParamApi(key,'Map').then(res => {
        res.json().then(data=>{
            if (data && data['error']){
                dispatch({ type: TypeAppVerError, error: data['error']})
            }else if (data && data['keyMapValue']) {
                const keyData = data['keyMapValue']
                let appUpdate = false 
                const compare = compareVersions(keyData['appVersion'],Package.version)
                
                if (compare === 1) {
                    appUpdate = true
                }
                const appUpdateTitle = keyData['appUpdateTitle']
                const appVersion = keyData['appVersion']
                const appUpdateNotes = keyData['appUpdateNotes']
                dispatch({ type: TypeAppVerReceived, appUpdate, appVersion, appUpdateTitle, appUpdateNotes })
            }
        })
    }).catch(err => {
       dispatch({ type: TypeAppVerError, error: err})
    })
} 