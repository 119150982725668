//@flow
const stateTaskName = 'adminTask'
const taskLocalStore = localStorage.getItem(stateTaskName)

export const getStateName=()=> {
    return stateTaskName
}

export const adminTaskInitialState = taskLocalStore?JSON.parse(taskLocalStore):{
	[stateTaskName]: { 
        error: '',
        taskType: '', 
        tasks: [],
        pageSize: 5,
		page: 1,
		pages: 0,
		sortField: 'updated_at',
		sortOrder: 'desc',
		count: 0,
		search: '',
		loading: false
	}
}

export type Action = {
    error: string,
    tasks: Array<any>,
    pageSize:number,
    page:number,
    pages:number,
    sortField:string,
    sortOrder:string,
    count:number,
    taskType:string,
    search:string
}

type State = {
    +adminTask: {
        +error: string,
        +tasks: Array<any>,
        +loading: boolean,
        +pageSize:number,
        +page:number,
        +pages:number,
        +sortField:string,
        +sortOrder:string,
        +count:number,
        +taskType:string,
        +search:string
    }
}

export const ActionTaskWorkflowsFetch = 'taskWorkflowsFetch'
export const ActionTaskWorkflowsReceived = 'taskWorkflowsReceived'
export const ActionAdminTaskError = 'adminTaskError'

export const adminTaskActions = {
    [ActionTaskWorkflowsFetch]: (state:State) => {
        const thisState = {
            [stateTaskName]: {
                ...state.adminTask,
                error: '',
                loading: true,
                tasks: [],
                pageSize: 20,
                page: 1,
                pages: 0,
                sortField: 'updated_at',
                sortOrder: 'desc',
                count: 0,
            }
        }
        localStorage.setItem(stateTaskName,JSON.stringify(thisState))
		return thisState
    },
    [ActionTaskWorkflowsReceived]: (state:State, action:Action) => {
        const thisState = {
            [stateTaskName]: {
                ...state.adminTask,
                error: '',
                loading: false,
                tasks: action.tasks,
                pageSize: action.pageSize,
                page:action.page,
                pages:action.pages,
                sortField:action.sortField,
                sortOrder:action.sortOrder,
                count:action.count,
                taskType:action.taskType,
                search:action.search
            }
        }
        localStorage.setItem(stateTaskName,JSON.stringify(thisState))
		return thisState
    },
    [ActionAdminTaskError]: (state:State, action:Action) => {
        const thisState = {
            [stateTaskName]: {
                ...state.adminTask,
                error: action.error,
                loading: false,
                tasks: []
            }
        }
        localStorage.setItem(stateTaskName,JSON.stringify(thisState))
		return thisState
    }
}