//@flow
const batchResStateName = 'batchResultset'
const batchResLocalStore = localStorage.getItem(batchResStateName)

export const getStateName=()=> {
    return batchResStateName
}

export const batchResInitialState = batchResLocalStore?JSON.parse(batchResLocalStore):{
	[batchResStateName]: { 
		error: '', 
        datas: [],
        workflowType:'',
        runId: 0,
        runBatchNo:'',
        linkFrom: '',
        title: '',
        pageRoutePath: '/',
        batchRunSelected:false,
        dataUpdated: false,
        isMultiPageDataLoading: false,
        generic: {}
	}
}

type Action = {
    error: string,
    datas: Array<any>,
    workflowType:string,
    runId:number,
    runBatchNo:string,
    pageSize:number,
    page:number,
    pages:number,
    sortField:string,
    sortOrder:string,
    count:number,
    search:string,
    linkFrom:string,
    title:string,
    pageRoutePath:string,
    genericDatas: Array<any>,
    genericType: string,
    genericDataMap: any,
    genericStatuses: Array<string>
}

type State = {
    +batchResultset: {
        +error: string,
        +datas: Array<any>,
        +workflowType:string,
        +runId:number,
        +runBatchNo:string,
        +linkFrom:string,
        +title:string,
        +pageRoutePath:string,
        +batchRunSelected:boolean,
        +dataUpdated:boolean,
        +generic: any
    }
}

export const ActionRunSelectedForBatchRes = 'runSelectedForBatchRes'
export const ActionBatchResFetch = 'batchResFetch'
export const ActionBatchResReceived = 'batchResReceived'
export const ActionBatchResError = 'batchResError'
export const ActionBatchResUpdated = 'batchResUpdated'

export const TypeMultiPageOfBatchResGenericFetch = 'multiPageOfBatchResGenericFetch'
export const TypeMultiPageOfBatchResGenericReceived = 'multiPageOfBatchResGenericReceived'
export const TypeMultiPageOfBatchResGenericError = 'multiPageOfBatchResGenericError'

export const batchResActions = {
    [ActionBatchResUpdated]: (state:State,action:Action) => {
        const genericState = state[batchResStateName].generic?state[batchResStateName].generic:{}
        const generic = {
            ...genericState,
            [action.genericType]: {
                ...genericState[action.genericType],
                loading: true
            }
        }

        const thisState = {
            [batchResStateName]: {
                ...state[batchResStateName],
                generic: generic,
                dataUpdated: true
            }
        }
        localStorage.setItem(batchResStateName,JSON.stringify(thisState))
		return thisState
    },
    [ActionRunSelectedForBatchRes]: (state:State,action:Action) => {
        const thisState = {
            [batchResStateName]: {
                ...state[batchResStateName],
                linkFrom: action.linkFrom,
                title: action.title,
                batchRunSelected:true,
                isMultiPageDataLoading: false,
                pageRoutePath: action.pageRoutePath,
                workflowType: action.workflowType,
                runId: action.runId,
                runBatchNo: action.runBatchNo,
            }
        }
        localStorage.setItem(batchResStateName,JSON.stringify(thisState))
		return thisState
    },
    [TypeMultiPageOfBatchResGenericFetch]: (state:State,action:Action)=>{
        const genericState = state[batchResStateName].generic?state[batchResStateName].generic:{}
        let generic = {...genericState}
        
        action.genericStatuses.forEach(status => {
            generic = {
                ...generic,
                [status]: {
                    error: '',
                    loading: true,
                    datas: [],
                    page: 1,
                    pages: 0,
                    sortField: 'created_at',
                    sortOrder: 'desc',
                    count: 0,
                    pageSize: 5,
                }
            }
        })

        const thisState = {
            [batchResStateName]: {
                ...state[batchResStateName],
                generic: generic,
                batchRunSelected: false,
                dataUpdated: false,
                isMultiPageDataLoading: true
            }
        }
        localStorage.setItem(batchResStateName,JSON.stringify(thisState))
		return thisState
    },
    [ActionBatchResFetch]: (state:State,action:Action) => {
        const genericState = state[batchResStateName].generic?state[batchResStateName].generic:{}
        const generic = {
            ...genericState,
            [action.genericType]: {
                error: '',
                loading: true,
                datas: [],
                page: 1,
                pages: 0,
                sortField: 'created_at',
                sortOrder: 'desc',
                count: 0,
                pageSize: 5,
            }
        }
        const thisState = {
            [batchResStateName]: {
                ...state[batchResStateName],
                generic: generic,
                batchRunSelected: false,
                dataUpdated: false
            }
        }
        localStorage.setItem(batchResStateName,JSON.stringify(thisState))
		return thisState
    },
    [TypeMultiPageOfBatchResGenericError]: (state:State,action:Action)=>{
        const genericState = state[batchResStateName].generic?state[batchResStateName].generic:{}
        let generic = {...genericState}
        
        action.genericStatuses.forEach(status => {
            generic = {
                ...generic,
                [status]: {
                    error: action.error,
                    loading: false,
                    datas: [],
                }
            }
        })

        const thisState = {
            [batchResStateName]: {
                ...state[batchResStateName],
                generic: generic,
                dataUpdated: false,
                isMultiPageDataLoading: false
            }
        }
        localStorage.setItem(batchResStateName,JSON.stringify(thisState))
		return thisState    
    },
    [ActionBatchResError]: (state:State,action:Action) => {
        const genericState = state[batchResStateName].generic?state[batchResStateName].generic:{}
        const generic = {
            ...genericState,
            [action.genericType]: {
                error: action.error,
                loading: false,
                datas: [],
            }
        }
        const thisState = {
            [batchResStateName]: {
                ...state[batchResStateName],
                generic: generic,
                dataUpdated: false,
            }
        }
        localStorage.setItem(batchResStateName,JSON.stringify(thisState))
		return thisState
    },
    [TypeMultiPageOfBatchResGenericReceived]: (state:State,action:Action)=>{
        const genericState = state[batchResStateName].generic?state[batchResStateName].generic:{}
        let generic = {...genericState}
        
        action.genericStatuses.forEach(status => {
            const data = action.genericDataMap[status]
            generic = {
                ...generic,
                [status]: {
                    error: '',
                    loading: false,
                    datas: data.results,
                    pageSize: data.pageSize,
                    page: data.page,
                    pages: data.pages,
                    sortField: data.sortField,
                    sortOrder: data.sortOrder,
                    count: data.count,
                    search: data.search,
                }
            }
        })

        const thisState = {
            [batchResStateName]: {
                ...state[batchResStateName],
                generic: generic,
                isMultiPageDataLoading: false
            }
        }
        localStorage.setItem(batchResStateName,JSON.stringify(thisState))
		return thisState
    },
    [ActionBatchResReceived]: (state:State,action:Action) => {
        const genericState = state[batchResStateName].generic?state[batchResStateName].generic:{}
        const generic = {
            ...genericState,
            [action.genericType]: {
                error: '',
                loading: false,
                datas: action.genericDatas,
                pageSize: action.pageSize,
                page: action.page,
                pages:action.pages,
                sortField:action.sortField,
                sortOrder:action.sortOrder,
                count:action.count,
                search:action.search,
            }
        }

        const thisState = {
            [batchResStateName]: {
                ...state[batchResStateName],
                generic: generic,
            }
        }
        localStorage.setItem(batchResStateName,JSON.stringify(thisState))
		return thisState
    } 
}