//@flow
import * as adminApis from '@lesofi/phoenix-js/lib/phoenixgoapi/admin'
import { ActionLogin, ActionLogout, ActionLoginError } from '../store/user'
import * as batchRunStore from '../store/batchRun'
import * as batchResultseStore from '../store/batchResultset'

export const getUserSession=(dispatch:any)=>{
    adminApis.getAdminSessionApi().then(res=>{
        if (res.status === 401){
            dispatch({ type: ActionLogout }) 
            clearLocalStorage()
        }else if (res.status === 200){
            res.json().then(data => {
                if (typeof data.error !== 'undefined'){
                    dispatch({ type: ActionLoginError, error: data.error })            
                }else {
                    dispatch({ type: ActionLogin, userInfo: data.user, jwtToken: data.token })            
                }
            })
        }
    }).catch(err=>{
        // To handle error here
        dispatch({ type: ActionLoginError, error: err })  
    })
}

function clearLocalStorage(){
    localStorage.removeItem(batchRunStore.getStateName())
    localStorage.removeItem(batchResultseStore.getStateName())
}