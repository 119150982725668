//@flow
const batchRunStateName = 'batchRun'
const batchRunLocalStore = localStorage.getItem(batchRunStateName)

export const getStateName=()=> {
    return batchRunStateName
}

export const batchRunInitialState = batchRunLocalStore?JSON.parse(batchRunLocalStore):{
	[batchRunStateName]: { 
		error: '', 
        datas: [],
        workflowType:'',
        crowdGroupId:0,
        linkFrom: '',
        title: '',
        pageRoutePath: '/',
        batchRunSelected:false,
        dataUpdated: false,
        isMultiPageDataLoading: false,
        generic: {}
	}
}

type Action = {
    error: string,
    datas: Array<any>,
    workflowType:string,
    crowdGroupId:number,
    pageSize:number,
    page:number,
    pages:number,
    sortField:string,
    sortOrder:string,
    count:number,
    search:string,
    linkFrom:string,
    title:string,
    pageRoutePath:string,
    genericDatas: Array<any>,
    genericType: string,
    genericDataMap: any,
    genericStatuses: Array<string>
}

type State = {
    +batchRun: {
        +error: string,
        +datas: Array<any>,
        +workflowType:string,
        +crowdGroupId:number,
        +linkFrom:string,
        +title:string,
        +pageRoutePath:string,
        +batchRunSelected:boolean,
        +dataUpdated:boolean,
        +generic: any
    }
}

export const ActionBatchRunSelected = 'batchRunSelected'
export const ActionBatchRunUpdated = 'batchRunUpdated'
export const TypeBatchRunGenericFetch = 'batchRunGenericFetch'//not in used!
export const TypeBatchRunGenericReceived = 'batchRunGenericReceived'
export const TypeBatchRunGenericError = 'batchRunGenericError'
export const TypeBatchRunGenericPageUpdate = 'batchRunGenericPageUpdate'

export const TypeMultiPageOfBatchRunsGenericFetch = 'multiPageOfBatchRunsGenericFetch'
export const TypeMultiPageOfBatchRunsGenericReceived = 'multiPageOfBatchRunsGenericReceived'
export const TypeMultiPageOfBatchRunsGenericError = 'multiPageOfBatchRunsGenericError'

export const batchRunActions = {
    [ActionBatchRunUpdated]: (state:State) => {
        const thisState = {
            [batchRunStateName]: {
                ...state[batchRunStateName],
                dataUpdated: true,
            }
        }
        localStorage.setItem(batchRunStateName,JSON.stringify(thisState))
		return thisState
    },
    [ActionBatchRunSelected]: (state:State,action:Action) => {
        const thisState = {
            [batchRunStateName]: {
                ...state[batchRunStateName],
                crowdGroupId: action.crowdGroupId,
                linkFrom: action.linkFrom,
                title: action.title,
                batchRunSelected:true,
                isMultiPageDataLoading: false,
                pageRoutePath: action.pageRoutePath,
                workflowType: action.workflowType,
                datas: []
            }
        }
        localStorage.setItem(batchRunStateName,JSON.stringify(thisState))
		return thisState
    },
    [TypeMultiPageOfBatchRunsGenericFetch]: (state:State,action:Action) => {
        const genericState = state[batchRunStateName].generic?state[batchRunStateName].generic:{}
        let generic = {...genericState}
        action.genericStatuses.forEach(status => {
            generic = {
                ...generic,
                [status]: {
                    error: '',
                    loading: true,
                    datas: [],
                    page: 1,
                    pages: 0,
                    sortField: 'created_at',
                    sortOrder: 'desc',
                    count: 0,
                    pageSize: 5,
                }
            }
        })

        const thisState = {
            [batchRunStateName]: {
                ...state[batchRunStateName],
                generic: generic,
                batchRunSelected: false,
                dataUpdated: false,
                isMultiPageDataLoading: true
            }
        }
        localStorage.setItem(batchRunStateName,JSON.stringify(thisState))
		return thisState
    },
    [TypeMultiPageOfBatchRunsGenericError]: (state:State,action:Action) => {
        const genericState = state[batchRunStateName].generic?state[batchRunStateName].generic:{}
        let generic = {...genericState}
        action.genericStatuses.forEach(status => {
            generic = {
                ...generic,
                [status]: {
                    error: action.error,
                    loading: false,
                    datas: [],
                }
            }
        })

        const thisState = {
            [batchRunStateName]: {
                ...state[batchRunStateName],
                generic: generic,
                batchRunSelected: false,
                dataUpdated: false,
                isMultiPageDataLoading: false
            }
        }
        localStorage.setItem(batchRunStateName,JSON.stringify(thisState))
		return thisState
    },
    [TypeMultiPageOfBatchRunsGenericReceived]: (state:State,action:Action) => {
        const genericState = state[batchRunStateName].generic?state[batchRunStateName].generic:{}
        let generic = {...genericState}
        
        action.genericStatuses.forEach(status => {
            const data = action.genericDataMap[status]
            generic = {
                ...generic,
                [status]: {
                    error: '',
                    loading: false,
                    datas: data.results,
                    pageSize: data.pageSize,
                    page: data.page,
                    pages: data.pages,
                    sortField: data.sortField,
                    sortOrder: data.sortOrder,
                    count: data.count,
                    search: data.search,
                }
            }
        })

        const thisState = {
            [batchRunStateName]: {
                ...state[batchRunStateName],
                generic: generic,
                isMultiPageDataLoading: false
            }
        }

        localStorage.setItem(batchRunStateName,JSON.stringify(thisState))
		return thisState
    },
    [TypeBatchRunGenericFetch]: (state:State,action:Action) => {
        const genericState = state[batchRunStateName].generic?state[batchRunStateName].generic:{}
        const generic = {
            ...genericState,
            [action.genericType]: {
                error: '',
                loading: true,
                datas: [],
                page: 1,
                pages: 0,
                sortField: 'created_at',
                sortOrder: 'desc',
                count: 0,
                pageSize: 5,
            }
        }

        const thisState = {
            [batchRunStateName]: {
                ...state[batchRunStateName],
                generic: generic,
                batchRunSelected: false,
                dataUpdated: false,
            }
        }

        localStorage.setItem(batchRunStateName,JSON.stringify(thisState))
		return thisState
    },
    [TypeBatchRunGenericError]: (state:State,action:Action) => {
        const genericState = state[batchRunStateName].generic?state[batchRunStateName].generic:{}
        const generic = {
            ...genericState,
            [action.genericType]: {
                error: action.error,
                loading: false,
                datas: [],
            }
        }

        const thisState = {
            [batchRunStateName]: {
                ...state[batchRunStateName],
                generic: generic,
                dataUpdated: false,
            }
        }

        localStorage.setItem(batchRunStateName,JSON.stringify(thisState))
		return thisState
    },
    [TypeBatchRunGenericReceived]: (state:State,action:Action) => {
        const genericState = state[batchRunStateName].generic?state[batchRunStateName].generic:{}
        const generic = {
            ...genericState,
            [action.genericType]: {
                error: '',
                loading: false,
                datas: action.genericDatas,
                pageSize: action.pageSize,
                page: action.page,
                pages:action.pages,
                sortField:action.sortField,
                sortOrder:action.sortOrder,
                count:action.count,
                search:action.search,
            }
        }

        const thisState = {
            [batchRunStateName]: {
                ...state[batchRunStateName],
                generic: generic,
            }
        }

        localStorage.setItem(batchRunStateName,JSON.stringify(thisState))
		return thisState
    },
    [TypeBatchRunGenericPageUpdate]: (state:State,action:Action)=>{
        const genericState = state[batchRunStateName].generic?state[batchRunStateName].generic:{}
        const generic = {
            ...genericState,
            [action.genericType]: {
                ...genericState[action.genericType],
                loading: true
            }
        }

        const thisState = {
            [batchRunStateName]: {
                ...state[batchRunStateName],
                generic: generic,
                dataUpdated: true,
            }
        }

        localStorage.setItem(batchRunStateName,JSON.stringify(thisState))
		return thisState
    }
}