//@flow
import React,{Suspense,lazy,useEffect} from 'react';
import { Switch, Route, BrowserRouter as Router,Redirect  } from 'react-router-dom'
import AppHeader from './components/top-level/AppHeader'
import AppFooter from './components/top-level/AppFooter'
import { useStore } from './store/useStore'
import Spinner from './components/top-level/Spinner'
import Analytics from 'react-router-ga'
import { getUserSession } from './services/user-util'
import { refreshAppUserVersion,getAppVersion } from './services/useAppVersion'

const Login = lazy(()=> import('./components/user/Login'))
const Profile = lazy(()=> import('./components/user/Profile'))
const Production = lazy(()=> import('./components/containers/Production'))
const NotFound = lazy(()=> import('./components/other/NotFound'))
const Qualification = lazy(()=> import('./components/containers/Qualification'))
const Training = lazy(()=> import('./components/containers/Training'))
const TaskReports = lazy(()=> import('./components/TaskReports'))
const WorkersTasksCounts = lazy(()=> import('./components/reports/WorkersTasksCounts'))
const WorkersTasksCountsByFormType = lazy(()=> import('./components/reports/WorkersTasksCountsByFormType'))
const WorkersXMLsCounts = lazy(()=> import('./components/reports/WorkersXMLsCounts'))
const MgmtCounts = lazy(()=> import('./components/reports/MgmtCounts'))
const BatchRuns = lazy(()=> import('./components/runs/BatchRuns'))
const BatchResultsets = lazy(()=> import('./components/runs/BatchResultsets'))
const AppUpdate = lazy(()=> import('./components/other/AppUpdate'))
const Tier1FiledropCounts = lazy(()=> import('./components/filedrop/Tier1FiledropCounts') )
const Tier2FiledropCounts = lazy(()=> import('./components/filedrop/Tier2FiledropCounts') )
const Tier3FiledropDetail = lazy(()=> import('./components/filedrop/Tier3FiledropDetail') )
const PageDataTable = lazy(()=> import('./components/generic/PageDataTable'))

const PrivateRoute = ({component: Component, authed, ...rest}) => {  
  return (    
       <Route {...rest} render={(props) => authed === true 
         ? <Component {...props} /> 
         : <Redirect to={{pathname: '/login', state: {from: props.location }}} />} />  
  )
}

function App() {
  const { dispatch, state } = useStore()
  const appVerAppUpdate = state.appVer && state.appVer.appUpdate 
  const loggedIn = state.user.loggedIn

  useEffect(()=>{
    getUserSession(dispatch)
  },[dispatch])

  useEffect(()=> {
    if (loggedIn){
      getAppVersion(dispatch)
      refreshAppUserVersion()
    }
  },[dispatch,loggedIn])

  return (
    <React.Fragment>
      <Router>
          <AppHeader />
          <main>
            <Analytics id="UA-93381200-7">
              <Suspense fallback={<Spinner />}> 
              <Switch>
                  <Route path="/login" exact={true} component={Login} />
                  <PrivateRoute authed={state.user.loggedIn} exact={true} path='/profile' component = {appVerAppUpdate?AppUpdate:Profile} />
                  <PrivateRoute authed={state.user.loggedIn} exact={true} path='/' component = {appVerAppUpdate?AppUpdate:Production} />
                  <PrivateRoute authed={state.user.loggedIn} exact={true} path='/qualification' component = {appVerAppUpdate?AppUpdate:Qualification} />
                  <PrivateRoute authed={state.user.loggedIn} exact={true} path='/training' component = {appVerAppUpdate?AppUpdate:Training} />
                  <PrivateRoute authed={state.user.loggedIn} exact={true} path='/taskReports' component = {appVerAppUpdate?AppUpdate:TaskReports} />
                  <PrivateRoute authed={state.user.loggedIn} exact={true} path='/reports/workersTasksCounts' component = {appVerAppUpdate?AppUpdate:WorkersTasksCounts} />
                  <PrivateRoute authed={state.user.loggedIn} exact={true} path='/reports/byFormType/workersTasksCounts' component = {appVerAppUpdate?AppUpdate:WorkersTasksCountsByFormType} />
                  <PrivateRoute authed={state.user.loggedIn} exact={true} path='/reports/WorkersXMLsCounts' component = {appVerAppUpdate?AppUpdate:WorkersXMLsCounts} />
                  <PrivateRoute authed={state.user.loggedIn} exact={true} path='/reports/mgmtCounts' component = {appVerAppUpdate?AppUpdate:MgmtCounts} />
                  <PrivateRoute authed={state.user.loggedIn} exact={true} path='/batchRuns' component = {appVerAppUpdate?AppUpdate:BatchRuns} />
                  <PrivateRoute authed={state.user.loggedIn} exact={true} path='/batchResultsets' component = {appVerAppUpdate?AppUpdate:BatchResultsets} />
                  <PrivateRoute authed={state.user.loggedIn} exact={true} path='/filedrop/tier1CountsReport' component = {appVerAppUpdate?AppUpdate:Tier1FiledropCounts} />
                  <PrivateRoute authed={state.user.loggedIn} exact={true} path='/filedrop/tier2CountsReport/:projectId' component = {appVerAppUpdate?AppUpdate:Tier2FiledropCounts} />
                  <PrivateRoute authed={state.user.loggedIn} exact={true} path='/filedrop/tier3FilesReport/:projectId/:fileDropCountId/:fileDropId' component = {appVerAppUpdate?AppUpdate:Tier3FiledropDetail} />
                  <PrivateRoute authed={state.user.loggedIn} exact={true} path='/pageDataTable/:pageDataId/:queryPath' component = {appVerAppUpdate?AppUpdate:PageDataTable} />
                  <Route >
                    <NotFound />
                  </Route>
                </Switch>
              </Suspense>
            </Analytics>
          </main>
          <AppFooter />
      </Router>
    </React.Fragment>
  );
}

export default App;
