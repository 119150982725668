//@flow
const mgmtCountsStateName = 'mgmtCounts'
const mgmtCountsLocalStore = localStorage.getItem(mgmtCountsStateName)

export const getStateName=()=> {
    return mgmtCountsStateName
}

export const mgmtCountsInitialState = mgmtCountsLocalStore?JSON.parse(mgmtCountsLocalStore):{
    [mgmtCountsStateName]: {
        error: '',
        datas: [],
        workflowType:'',
        crowdGroupId:0,
        pageSize: 20,
		page: 1,
		pages: 0,
		sortField: 'batch',
		sortOrder: 'desc',
		count: 0,
		search: '',
        loading: false,
        linkFrom: '',
        title: '',
        pageRoutePath: '/'
    }
}

type Action = {
    error: string,
    datas: Array<any>,
    workflowType:string,
    crowdGroupId:number,
    pageSize:number,
    page:number,
    pages:number,
    sortField:string,
    sortOrder:string,
    count:number,
    search:string,
    linkFrom:string,
    title:string,
    pageRoutePath:string
}

type State = {
    +mgmtCounts: {
        +error: string,
        +datas: Array<any>,
        +workflowType:string,
        +crowdGroupId:number,
        +pageSize:number,
        +page:number,
        +pages:number,
        +sortField:string,
        +sortOrder:string,
        +count:number,
        +search:string,
        +loading:boolean,
        +linkFrom:string,
        +title:string,
        +pageRoutePath:string,
    }
}

export const ActionMgmtCountsFetch = 'mgmtCountsFetch'
export const ActionMgmtCountsReceived = 'mgmtCountsReceived'
export const ActionMgmtCountsError = 'mgmtCountsError'

export const mgmtCountsActions = {
    [ActionMgmtCountsFetch]: (state:State) => {
        const thisState = {
            [mgmtCountsStateName]: {
                ...state[mgmtCountsStateName],
                error: '',
                loading: true,
                datas: [],
                page: 1,
                pages: 0,
                sortField: 'batch',
                sortOrder: 'desc',
                count: 0,
            }
        }
        localStorage.setItem(mgmtCountsStateName,JSON.stringify(thisState))
		return thisState
    },
    [ActionMgmtCountsError]: (state:State, action:Action) => {
        const thisState = {
            [mgmtCountsStateName]: {
                ...state[mgmtCountsStateName],
                error: action.error,
                loading: false,
                datas: [],
            }
        }
        localStorage.setItem(mgmtCountsStateName,JSON.stringify(thisState))
		return thisState
    },
    [ActionMgmtCountsReceived]: (state:State, action:Action) => {
        const thisState = {
            [mgmtCountsStateName]: {
                ...state[mgmtCountsStateName],
                error: '',
                loading: false,
                datas: action.datas,
                pageSize: action.pageSize,
                page:action.page,
                pages:action.pages,
                sortField:action.sortField,
                sortOrder:action.sortOrder,
                count:action.count,
                search:action.search,
            }
        }
        localStorage.setItem(mgmtCountsStateName,JSON.stringify(thisState))
		return thisState
    }
}