//@flow
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Package  from '../../../package.json'

function AppVersion(){
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      App Version {Package.version}
    </Typography>
  )
}

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://gigsdata.com/">
            LESOFI LLP
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}

function OperatingPartner(){
  return (
    <Typography variant="body2" color="textSecondary" align="center">
        {'Operating Partner: Dataswarm LLC'}
    </Typography>
  )
}

function PrivacyAndTermLink(){
  const classes = useStyles()

  return (
    <div className={classes.privacyRoot}>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item >
            <Typography variant="body2" color="textSecondary" align="center" >
              <Link color="inherit" href="https://gigsdata.com/privacy">
              Privacy Policy
              </Link>
            </Typography>
        </Grid>
        <Grid item  >
            <Typography variant="body2" color="textSecondary" align="center">
              <Link color="inherit" href="https://gigsdata.com/termOfService">
                Term of Service
              </Link>  
            </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
    },
    privacyRoot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > *': {
        margin: theme.spacing(1),
      },
    }
}));

function AppFooter(){
    const classes = useStyles()
    return (
      <footer className={classes.footer}>
        <OperatingPartner />
        <Copyright />
        <PrivacyAndTermLink />
        <AppVersion />
      </footer>
    )
}

export default React.memo<{}>(AppFooter)