//@flow
const taskReportsStateName = 'adminTaskReports'
const taskReportslocalStore = localStorage.getItem(taskReportsStateName)

export const getStateName=()=> {
    return taskReportsStateName
}

export const taskReportsInitialState = taskReportslocalStore?JSON.parse(taskReportslocalStore):{
	[taskReportsStateName]: { 
		error: '', 
        datas: [],
        taskName:'',
        crowdGroupId:0,
        pageSize: 5,
		page: 1,
		pages: 0,
		sortField: 'report_name',
		sortOrder: 'asc',
		count: 0,
		search: '',
        loading: false,
        taskSelected: false,
        from: '/'
	}
}

type Action = {
    error: string,
    datas: Array<any>,
    taskName:string,
    crowdGroupId:number,
    pageSize:number,
    page:number,
    pages:number,
    sortField:string,
    sortOrder:string,
    count:number,
    search:string,
    from:string
}

type State = {
    +adminTaskReports: {
        +error: string,
        +datas: Array<any>,
        +taskName:string,
        +crowdGroupId:number,
        +pageSize:number,
        +page:number,
        +pages:number,
        +sortField:string,
        +sortOrder:string,
        +count:number,
        +search:string,
        +loading: boolean,
        +taskSelected: boolean,
        +from:string
    }
}

export const ActionTaskSelected = 'taskSelected'
export const ActionTaskReportsFetch = 'taskReportsFetch'
export const ActionTaskReportsReceived = 'taskReportsReceived'
export const ActionTaskReportsError = 'taskReportsError'

export const taskReportsActions = {
    [ActionTaskSelected]: (state:State,action:Action) => {
        const thisState = {
            [taskReportsStateName]: {
                ...state[taskReportsStateName],
                taskName: action.taskName,
                crowdGroupId: action.crowdGroupId,
                taskSelected: true,
                from: action.from
            }
        }
        localStorage.setItem(taskReportsStateName,JSON.stringify(thisState))
		return thisState
    },
    [ActionTaskReportsFetch]: (state:State) => {
        const thisState = {
            [taskReportsStateName]: {
                ...state[taskReportsStateName],
                error: '',
                loading: true,
                datas: [],
                page: 1,
                pages: 0,
                sortField: 'report_name',
                sortOrder: 'asc',
                count: 0,
                taskSelected: false
            }
        }
        localStorage.setItem(taskReportsStateName,JSON.stringify(thisState))
		return thisState
    },
    [ActionTaskReportsReceived]: (state:State, action:Action) => {
        const thisState = {
            [taskReportsStateName]: {
                ...state[taskReportsStateName],
                error: '',
                loading: false,
                taskSelected: false,
                datas: action.datas,
                pageSize: action.pageSize,
                page:action.page,
                pages:action.pages,
                sortField:action.sortField,
                sortOrder:action.sortOrder,
                count:action.count,
                search:action.search
            }
        }
        localStorage.setItem(taskReportsStateName,JSON.stringify(thisState))
		return thisState
    },
    [ActionTaskReportsError]: (state:State, action:Action) => {
        const thisState = {
            [taskReportsStateName]: {
                ...state[taskReportsStateName],
                error: action.error,
                loading: false,
                datas: []
            }
        }
        localStorage.setItem(taskReportsStateName,JSON.stringify(thisState))
		return thisState
    }
}