//@flow
const fileDropStateName = 'fileDrop'
const fileDropSessionStore = sessionStorage.getItem(fileDropStateName)

export const getStateName=()=> {
    return fileDropStateName
}

export const fileDropInitialState = fileDropSessionStore?JSON.parse(fileDropSessionStore):{
	[fileDropStateName]: { 
		error: '', 
        linkFrom: '',
        pageRoutePath: '/',
        dataUpdated: false,
        isMultiPageDataLoading: false,
        tier1Title: 'Tier 1 - Filedrop Counts Summary',
        tier1Data: {},
        tier2Title: 'Tier 2 - Filedrop Counts by Project',
        tier2Data: {},
        tier3Title: 'Tier 3 - Filedrop Detail',
        tier3Data: {}
	}
}

type State = {
    +fileDrop: {
        +error: string,
        +linkFrom: string,
        +pageRoutePath: string,
        +dataUpdated: boolean,
        +isMultiPageDataLoading: boolean,
        +tier1Title: string,
        +tier1Data: any,
        +tier2Title: string,
        +tier2Data: any,
        +tier3Title: string,
        +tier3Data: any
    }
}

type Action = {
    error: string,
    pageData: any,
    multiPageOfData: any, 
    multiPageOfStatuses: Array<string>,
    pageStatus: string,
    dataRootName: string,
    defaultSortField: string,
    defaultSortOrder: string
}

// action types for filedrop counts summary
export const TypeMultiPageOfFiledropDataFetch = 'multiPageOfFiledropDataFetch'
export const TypeMultiPageOfFiledropDataReceived = 'multiPageOfFiledropDataReceived'
export const TypeMultiPageOfFiledropDataError = 'multiPageOfFiledropDataError'
export const TypeSinglePageOfFiledropDataError = 'singlePageOfFiledropDataError'
export const TypeSinglePageOfFiledropDataReceived = 'singlePageOfFiledropDataReceived'

// action types for generic data update
export const TypePageDataUpdateRequest = 'pageDataUpdateRequest'

// action types for filedrop counts by project
export const TypeProjectFiledropCountsFetch = 'projectFileDropCountsFetch'
export const TypeProjectFiledropCountsError = 'projectFileDropCountsError'
export const TypeProjectFiledropCountsReceived = 'projectFileDropCountsReceived'

export const fileDropActions = {
    [TypeMultiPageOfFiledropDataFetch]: (state:State,action:Action) => {
        const dataRootState = state[fileDropStateName][action.dataRootName]?state[fileDropStateName][action.dataRootName]:{}
        let dataRoot = {...dataRootState}

        action.multiPageOfStatuses.forEach(status => {
            dataRoot = {
                ...dataRoot,
                [status]: {
                    error: '',
                    loading: true,
                    datas: [],
                    page: 1,
                    pages: 0,
                    sortField: action.defaultSortField,
                    sortOrder: action.defaultSortOrder,
                    count: 0,
                    pageSize: 5,
                }
            }
        })

        const thisState = {
            [fileDropStateName]: {
                ...state[fileDropStateName],
                [action.dataRootName]: dataRoot,
                dataUpdated: false,
                isMultiPageDataLoading: true
            }
        }

        sessionStorage.setItem(fileDropStateName,JSON.stringify(thisState))
		return thisState
    },
    [TypeMultiPageOfFiledropDataError]: (state:State,action:Action)=> {
        const dataRootState = state[fileDropStateName][action.dataRootName]?state[fileDropStateName][action.dataRootName]:{}
        let dataRoot = {...dataRootState}

        action.multiPageOfStatuses.forEach(status => {
            dataRoot = {
                ...dataRoot,
                [status]: {
                    error: action.error,
                    loading: false,
                    datas: [],
                }
            }
        })

        const thisState = {
            [fileDropStateName]: {
                ...state[fileDropStateName],
                [action.dataRootName]: dataRoot,
                dataUpdated: false,
                isMultiPageDataLoading: false
            }
        }

        sessionStorage.setItem(fileDropStateName,JSON.stringify(thisState))
		return thisState
    },
    [TypeMultiPageOfFiledropDataReceived]: (state:State,action:Action)=>{
        const dataRootState = state[fileDropStateName][action.dataRootName]?state[fileDropStateName][action.dataRootName]:{}
        let dataRoot = {...dataRootState}

        action.multiPageOfStatuses.forEach(status => {
            const data = action.multiPageOfData[status]
            dataRoot = {
                ...dataRoot,
                [status]: {
                    error: '',
                    loading: false,
                    datas: data?data.results:[],
                    page: data?data.page:1,
                    pages: data?data.pages:0,
                    sortField: data?data.sortField:'',
                    sortOrder: data?data.sortOrder:'',
                    count: data?data.count:0,
                    pageSize: data?data.pageSize:5,
                    search: data?data.search:''
                }
            }
        })

        const thisState = {
            [fileDropStateName]: {
                ...state[fileDropStateName],
                [action.dataRootName]: dataRoot,
                dataUpdated: false,
                isMultiPageDataLoading: false
            }
        }

        sessionStorage.setItem(fileDropStateName,JSON.stringify(thisState))
		return thisState
    },
    [TypeSinglePageOfFiledropDataError]: (state:State,action:Action)=> {
        const dataRootState = state[fileDropStateName][action.dataRootName]?state[fileDropStateName][action.dataRootName]:{}
        let dataRoot = {...dataRootState}

        dataRoot = {
            ...dataRoot,
            [action.pageStatus]: {
                error: action.error,
                loading: false,
                datas: [],
            }
        }
        
        const thisState = {
            [fileDropStateName]: {
                ...state[fileDropStateName],
                [action.dataRootName]: dataRoot,
                dataUpdated: false,
                isMultiPageDataLoading: false
            }
        }

        sessionStorage.setItem(fileDropStateName,JSON.stringify(thisState))
		return thisState
    },
    [TypeSinglePageOfFiledropDataReceived]: (state:State,action:Action)=>{
        const dataRootState = state[fileDropStateName][action.dataRootName]?state[fileDropStateName][action.dataRootName]:{}
        let dataRoot = {...dataRootState}

        dataRoot = {
            ...dataRoot,
            [action.pageStatus]: {
                error: '',
                loading: false,
                datas: action.pageData.results,
                page: action.pageData.page,
                pages: action.pageData.pages,
                sortField: action.pageData.sortField,
                sortOrder: action.pageData.sortOrder,
                count: action.pageData.count,
                pageSize: action.pageData.pageSize,
                search: action.pageData.search
            }
        }
        
        const thisState = {
            [fileDropStateName]: {
                ...state[fileDropStateName],
                [action.dataRootName]: dataRoot,
                dataUpdated: false,
                isMultiPageDataLoading: false
            }
        }

        sessionStorage.setItem(fileDropStateName,JSON.stringify(thisState))
		return thisState
    },
    [TypePageDataUpdateRequest]: (state:State,action:Action) => {
        const dataRootState = state[fileDropStateName].tier1Data?state[fileDropStateName].tier1Data:{}
        let dataRoot = {...dataRootState}

        dataRoot = {
            ...dataRoot,
            [action.pageStatus]: {
                ...dataRootState[action.pageStatus],
                loading: true,
            }
        }
        
        const thisState = {
            [fileDropStateName]: {
                ...state[fileDropStateName],
                tier1Data: dataRoot,
                dataUpdated: true,
            }
        }

        sessionStorage.setItem(fileDropStateName,JSON.stringify(thisState))
		return thisState
    },
    [TypeProjectFiledropCountsFetch]: (state:State) => {
        const dataRootState = state[fileDropStateName].tier2Data?state[fileDropStateName].tier2Data:{}
        let dataRoot = {...dataRootState}

        dataRoot = {
            ...dataRoot,
            error: '',
            loading: true,
            datas: [],
            page: 1,
            pages: 0,
            sortField: 'total_progress',
            sortOrder: 'asc',
            count: 0,
            pageSize: 5,
        }
        
        const thisState = {
            [fileDropStateName]: {
                ...state[fileDropStateName],
                tier2Data: dataRoot,
            }
        }

        sessionStorage.setItem(fileDropStateName,JSON.stringify(thisState))
		return thisState
    },
    [TypeProjectFiledropCountsError]: (state:State,action:Action) => {
        const dataRootState = state[fileDropStateName].tier2Data?state[fileDropStateName].tier2Data:{}
        let dataRoot = {...dataRootState}

        dataRoot = {
            ...dataRoot,
            error: action.error,
            loading: false,
            datas: [],
        }
        
        const thisState = {
            [fileDropStateName]: {
                ...state[fileDropStateName],
                tier2Data: dataRoot,
            }
        }

        sessionStorage.setItem(fileDropStateName,JSON.stringify(thisState))
		return thisState
    },
    [TypeProjectFiledropCountsReceived]: (state:State,action:Action) => {
        const dataRootState = state[fileDropStateName].tier2Data?state[fileDropStateName].tier2Data:{}
        let dataRoot = {...dataRootState}

        dataRoot = {
            ...dataRoot,
            error: '',
            loading: false,
            datas: action.pageData.results,
            page: action.pageData.page,
            pages: action.pageData.pages,
            sortField: action.pageData.sortField,
            sortOrder: action.pageData.sortOrder,
            count: action.pageData.count,
            pageSize: action.pageData.pageSize,
            search: action.pageData.search
        }
        
        const thisState = {
            [fileDropStateName]: {
                ...state[fileDropStateName],
                tier2Data: dataRoot,
            }
        }

        sessionStorage.setItem(fileDropStateName,JSON.stringify(thisState))
		return thisState
    },
}