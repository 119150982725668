//@flow
const reportStateName = 'adminProjectReport'
const reportLocalStore = localStorage.getItem(reportStateName)

export const getStateName=()=> {
    return reportStateName
}

export const projectReportInitialState = reportLocalStore?JSON.parse(reportLocalStore):{
	[reportStateName]: { 
		error: '', 
        datas: [],
        workflowType:'',
        crowdGroupId:0,
        pageSize: 20,
		page: 1,
		pages: 0,
		sortField: 'yyyymmdd',
		sortOrder: 'desc',
		count: 0,
		search: '',
        loading: false,
        linkFrom: '',
        title: '',
        taskReportSelected: false,
        pageRoutePath: '/'
	}
}

type Action = {
    error: string,
    datas: Array<any>,
    workflowType:string,
    crowdGroupId:number,
    pageSize:number,
    page:number,
    pages:number,
    sortField:string,
    sortOrder:string,
    count:number,
    search:string,
    linkFrom:string,
    title:string,
    pageRoutePath:string
}

type State = {
    +adminProjectReport: {
        +error: string,
        +datas: Array<any>,
        +workflowType:string,
        +crowdGroupId:number,
        +pageSize:number,
        +page:number,
        +pages:number,
        +sortField:string,
        +sortOrder:string,
        +count:number,
        +search:string,
        +loading:boolean,
        +linkFrom:string,
        +title:string,
        +taskReportSelected:boolean,
        +pageRoutePath:string
    }
}

export const ActionTaskReportSelected = 'taskReportSelected'
export const ActionClearTaskReportSelected = 'clearTaskReportSelected'
export const ActionProjectReportFetch = 'projectReportFetch'
export const ActionProjectReportReceived = 'projectReportReceived'
export const ActionProjectReportError = 'projectReportError'

export const projectReportActions = {
    [ActionTaskReportSelected]: (state:State,action:Action) => {
        const thisState = {
            [reportStateName]: {
                ...state[reportStateName],
                crowdGroupId: action.crowdGroupId,
                linkFrom: action.linkFrom,
                title: action.title,
                taskReportSelected:true,
                pageRoutePath: action.pageRoutePath,
                workflowType: action.workflowType,
            }
        }
        localStorage.setItem(reportStateName,JSON.stringify(thisState))
		return thisState
    },
    [ActionClearTaskReportSelected]: (state:State) => {
        const thisState = {
            [reportStateName]: {
                ...state[reportStateName],
                taskReportSelected:false,
            }
        }
        localStorage.setItem(reportStateName,JSON.stringify(thisState))
		return thisState
    },
    [ActionProjectReportFetch]: (state:State) => {
        const thisState = {
            [reportStateName]: {
                ...state[reportStateName],
                error: '',
                loading: true,
                datas: [],
                page: 1,
                pages: 0,
                sortField: 'yyyymmdd',
                sortOrder: 'desc',
                count: 0,
                taskReportSelected: false
            }
        }
        localStorage.setItem(reportStateName,JSON.stringify(thisState))
		return thisState
    },
    [ActionProjectReportReceived]: (state:State, action:Action) => {
        const thisState = {
            [reportStateName]: {
                ...state[reportStateName],
                error: '',
                loading: false,
                datas: action.datas,
                workflowType: action.workflowType,
                crowdGroupId: action.crowdGroupId,
                pageSize: action.pageSize,
                page:action.page,
                pages:action.pages,
                sortField:action.sortField,
                sortOrder:action.sortOrder,
                count:action.count,
                search:action.search,
                taskReportSelected: false
            }
        }
        localStorage.setItem(reportStateName,JSON.stringify(thisState))
		return thisState
    },
    [ActionProjectReportError]: (state:State, action:Action) => {
        const thisState = {
            [reportStateName]: {
                ...state[reportStateName],
                error: action.error,
                loading: false,
                datas: [],
                taskReportSelected: false
            }
        }
        localStorage.setItem(reportStateName,JSON.stringify(thisState))
		return thisState
    }
}