//@flow
import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'

import { Link } from 'react-router-dom'
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks'
import { useStore } from '../../store/useStore'
import * as crowdApis from '@lesofi/phoenix-js/lib/phoenixgoapi/crowd'

const useStyles = makeStyles((theme)=>({
    appBar: {
        backgroundColor: '#3E414A',
        color: '#F3C817'
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        color: '#F3C817',
        textDecoration: 'none',
        "&:hover": {
          color: '#F3C817',
          textDecoration: 'none',  
        }
    },
}))

function AppHeader(){
    const classes = useStyles();
    const popupState = usePopupState({ variant: 'popover', popupId: 'userProfileMenu' })
    
    const { state, dispatch } = useStore()
    const logout = React.useCallback(() => {
      crowdApis.deleteWorkerSession().then(res => {
        if (res.status === 200) {
          dispatch({ type: "logout" })
        }
      })
      
    },[dispatch]);

    const handleLogout=()=>{
      popupState.close()
      logout()
    }

    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar >
            <Typography variant="h6" className={classes.title} component={Link} to='/'>
              Admin Gigsdata
            </Typography>
            {state.user.loggedIn?(
              <div>
                  <IconButton
                    aria-label="userProfileMenu"
                    aria-haspopup="true"
                    color="inherit"
                    {...bindTrigger(popupState)}
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem  component={Link} to='/profile' >Profile</MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
            </div>
            ):null}
          </Toolbar>
        </AppBar>
      </div>
    )
}

export default React.memo<{}>(AppHeader)